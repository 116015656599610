import { NavLink } from "react-router-dom";
import img from './bg-img2.jpg'
import './website.css'

function Main() {
	return <>
            <div className="bg-white text-gray-700">
                <header className="flex justify-between items-center py-4 px-8 bg-blue-800 text-white">
                    <div className="text-2xl font-bold">UKS Sahayog Foundation</div>
                    <nav>
                        <ul className="flex space-x-6">
                            <NavLink to='/auth' className='text-white bg-yellow-400 text-black px-4 py-2 rounded mr-4'>Login</NavLink>
                        </ul>
                    </nav>
                </header>
                <section
                    className="h-screen w-full relative flex items-center justify-center bg-cover bg-center"
                    style={{ backgroundImage: `url(${img})` }}>
                    <div className="bg-opacity-50 bg-black p-8 text-white text-center">
                        <h1 className="text-4xl font-bold">Empowering Dreams with Financial Freedom</h1>
                        <div className="mt-6">
                            <button className="bg-yellow-400 text-black px-4 py-2 rounded mr-4"><NavLink to='/auth' className='text-white mx-5'>Login</NavLink></button>
                        </div>
                    </div>
                </section>
                <footer className="bg-blue-800 text-white py-8">
                    <div className="max-w-6xl mx-auto flex justify-between">
                        <div>
                            <h4 className="font-bold">UKS Sahayog Foundation.</h4>
                            <p>Empowering financial independence since 2020</p>
                            <p>+91 9999988774</p>
                        </div>
                        <ul className="flex space-x-6">
                            <li><a href="#">Facebook</a></li>
                            <li><a href="#">LinkedIn</a></li>
                            <li><a href="#">Twitter</a></li>
                        </ul>
                    </div>
                </footer>
            </div>
		</>
}

export default Main;
